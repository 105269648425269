import React, { Component } from 'react';
import './App.css';
import GlitchClip from 'react-glitch-effect/core/Clip';
import GlitchText from 'react-glitch-effect/core/Text';



class App extends Component {
  state = {
    duration: '2s',
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      let random = Math.floor(Math.random() * 4) + 2;
      this.setState({ duration: random + 's' })
    }, 2300);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="App">
        <GlitchClip duration={this.state.duration}>
          <GlitchText component='h1'>
            coming soon
          </GlitchText>

        </GlitchClip>
        <p><a href="https://kevintrankt.com/"> visit my old page</a></p>
      </div >
    );
  }

}

export default App;
